import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import AppointmentOptionsContainer from "./containers/AppointmentOptionsContainer";
import QuestionnaireContainer from "./containers/QuestionnaireContainer";

export default props => (
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route
          exact
          path="/"
          render={props => (
            <AppointmentOptionsContainer
              history={props.history}
              location={props.location}
            />
          )}
        />
        <Route
          exact
          path="/appointments/:eventId?"
          render={props => (
            <AppointmentOptionsContainer
              history={props.history}
              location={props.location}
              eventId={props.match.params.eventId}
            />
          )}
        />
        <Route exact path="/forms/:formId" component={QuestionnaireContainer} />
      </Switch>
    </ScrollToTop>
  </BrowserRouter>
);

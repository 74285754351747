import React, { useRef, useEffect, useState } from "react";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import {
  createSlotAndAppointment,
  getPractitioners,
  getPractitioner,
  sendEmailAppointment,
  createNewPatientAndTask,
  getAvailableTimes,
  getPractitionerSchedule,
} from "../utils/asyncServerRequests";
import {
  getAvailabilityForPractitioner,
  sendEmailNotification,
  createApptMeta,
} from "../utils/asyncSettingsServerRequests";
import { withStyles, Hidden } from "@material-ui/core";
import {
  dropdown,
  date,
  renderCalendarPicker,
  text,
} from "./renderers/renderers";
import renderSubmitButton from "./renderers/SubmitFormButton";
import moment from "moment-timezone";
import { generateUuid } from "../utils/uuid";
import {
  createSlotResource,
  createAppointmentResource,
  createApptMetaResource,
  parseUserName,
  isObjEmpty,
} from "../utils/resources";
import { withSnackbar } from "notistack";
import EventSelectForm from "./subforms/EventSelectForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  REQUEST_AVAILABLE_SLOTS,
  FETCH_AVAILABLE_SLOTS_SUCCESS,
  FETCH_AVAILABLE_SLOTS_FAILED,
  SET_SCHEDULE_FETCHING,
  SET_APPOINTMENT_SUCCESS,
  FETCH_PRACTITIONERS_SUCCEEDED,
  FETCH_PRACTITIONERS_FAILED,
  FETCH_SCHEDULE_FAILED,
  FETCH_SCHEDULE_SUCCEEDED,
} from "../actions/actions";
import { errorClass } from "./consts";
import { STANDARD_DATE_FORMAT_NO_TIME } from "../consts";

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    minHeight: "50vh",
    maxHeight: "fit-content",
    minWidth: "fit-content",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttonProgress: {
    position: "relative",
    left: "50%",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "90%",
  },
  select: {
    minWidth: "fit-content",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  dialogPaper: {
    minHeight: "500",
  },
  paper: {
    minWidth: "fit-content",
    width: "80%",
  },
  error: {
    color: "red",
  },
});

let availability,
  scheduleId = null;
let timeSlotOptions = [];
let calendarDate = moment();

const AppointmentOptionsSchema = Yup.object().shape({
  event: Yup.string()
    .required("Required")
    .nullable(),
  date: Yup.date()
    .required("Required")
    .nullable(),
  timeSlot: Yup.string()
    .required("Required")
    .nullable(),
});

const AppointmentOptionsForm = props => {
  const {
    classes,
    enqueueSnackbar,
    events,
    fetching,
    fetched,
    scheduleFetched,
    error,
    getAvailableSlots,
    fetchAvailableSlotsSuccess,
    fetchAvailableSlotsFailed,
    setSettingsFetching,
    newPatient,
    fetchPractitionersFailed,
    fetchPractitionersSuccess,
  } = props;
  const [timeZone, setTimeZone] = useState(moment.tz.guess());
  const [practitionerUser, setPractitionerUser] = useState(null);
  const practitionerUserRef = useRef();
  const [practitioners, setPractitioners] = useState([]);
  const noEventsMessage = `Not able to book online. Please contact us directly at ${props.organization.phoneNumber}.`;
  const [eventOptions, setEventOptions] = useState([]);

  const handleEventChange = async (event, formProps) => {
    setSettingsFetching();
    const practitioners = await getPractitioners(event.event.practitioners);
    console.debug("zzz practitioners", practitioners);
    if (practitioners.code >= 400) {
      fetchPractitionersFailed();
      const message = "Error getting practitioners";
      enqueueSnackbar(message, {
        variant: "error",
      });
      return;
    } else {
      fetchPractitionersSuccess();
      setPractitioners(practitioners.data);
    }

    if (practitioners.data.length > 1) {
      return;
    } else if (practitioners.data.length === 1) {
      setSettingsFetching();
      const scheduleResponse = await getPractitionerSchedule(
        practitioners.data[0].practitionerId
      );
      if (scheduleResponse.code < 400 && scheduleResponse.scheduleId) {
        props.fetchScheduleSuccess();
        scheduleId = scheduleResponse.scheduleId;
      } else {
        props.fetchScheduleFailed();
        enqueueSnackbar("No schedule for clinician", {
          variant: "error",
        });
        if (formProps) {
          formProps.setFieldValue("practitioner", null);
        }
        return;
      }

      const availabilityResponse = await getAvailabilityForPractitioner(
        practitioners.data[0].practitionerId
      );
      if (availabilityResponse.code >= 400) {
        fetchAvailableSlotsFailed();
        const message = "Error getting available appointments";
        enqueueSnackbar(message, {
          variant: "error",
        });
        return;
      }

      availability = availabilityResponse.availability
        ? availabilityResponse.availability
        : practitioners.data[0].availability;
      practitionerUserRef.current = availabilityResponse.practitionerUser;
      setPractitionerUser(availabilityResponse.practitionerUser);
      handlePickDate(
        calendarDate,
        formProps,
        event,
        availabilityResponse.practitionerUser
      );
    } else {
      const message = "No available practitioners";
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    let matchingOptions = [];
    for (const option of events) {
      if (
        props.presetEventId &&
        option._id === props.presetEventId &&
        !option.archived &&
        ((option.availableToNewPatients && newPatient) ||
          (option.availableToExistingPatients && !newPatient))
      ) {
        matchingOptions.push(option);
        handleEventChange({ event: option });
        break;
      }
      if (!props.presetEventId) {
        if (option.availableToNewPatients && newPatient) {
          matchingOptions.push(option);
        } else if (option.availableToExistingPatients && !newPatient) {
          matchingOptions.push(option);
        }
      }
    }
    setEventOptions(matchingOptions);
  }, []);

  const handleDateChange = async (date, event, user = practitionerUser) => {
    getAvailableSlots();
    const timeSlotResponse = await getAvailableTimes(
      user.practitionerId,
      date,
      event.event._id,
      moment.tz.guess()
    );
    if (timeSlotResponse && timeSlotResponse.code < 400) {
      timeSlotOptions = timeSlotResponse.data ? timeSlotResponse.data : [];
    } else {
      fetchAvailableSlotsFailed();
      enqueueSnackbar(
        typeof timeSlotResponse.error === "string"
          ? timeSlotResponse.error
          : "Error retrieving availability",
        {
          variant: "error",
        }
      );
    }
    fetchAvailableSlotsSuccess();
  };

  const handlePractitionerChange = async (practitioner, formProps) => {
    setSettingsFetching();
    const scheduleResponse = await getPractitionerSchedule(practitioner.value);
    if (scheduleResponse.code < 400 && scheduleResponse.scheduleId) {
      scheduleId = scheduleResponse.scheduleId;
    } else {
      props.fetchScheduleFailed();
      enqueueSnackbar("No schedule for clinician", {
        variant: "error",
      });
      formProps.setFieldValue("practitioner", null);
      return;
    }
    const availabilityResponse = await getAvailabilityForPractitioner(
      practitioner.value
    );
    if (availabilityResponse.code >= 400) {
      fetchAvailableSlotsFailed();
      const message = "Error getting available appointments";
      enqueueSnackbar(message, {
        variant: "error",
      });
      return;
    } else {
      fetchAvailableSlotsSuccess();
      availability = availabilityResponse.availability;
      handlePickDate(
        calendarDate,
        formProps,
        formProps.values.event,
        availabilityResponse.practitionerUser
      );
      practitionerUserRef.current = availabilityResponse.practitionerUser;
      setPractitionerUser(availabilityResponse.practitionerUser);
    }
  };

  const handlePickDate = async (
    date,
    formProps,
    overrideEventField,
    user,
    retry = true
  ) => {
    if (!user && retry) {
      setTimeout(() => {
        handlePickDate(
          date,
          formProps,
          overrideEventField,
          practitionerUserRef.current,
          false
        );
      }, 500);
      return;
    }

    calendarDate = date;
    const utcDate = date.format(STANDARD_DATE_FORMAT_NO_TIME);
    await handleDateChange(
      utcDate,
      overrideEventField
        ? overrideEventField
        : formProps
        ? formProps.values.event
        : null,
      user
    );
    if (formProps) {
      formProps.setValues({
        ...formProps.values,
        event: overrideEventField
          ? overrideEventField
          : formProps
          ? formProps.values.event
          : null,
        date: utcDate,
        timeSlot: null,
      });
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      if (isObjEmpty(props.selectedPatient)) {
        enqueueSnackbar("An error occured. Please refresh page and try again", {
          variant: "error",
        });
        actions.setSubmitting(false);
        return;
      }

      let allQuestionsAnswered = true;
      if (
        values.event &&
        values.event.event &&
        Array.isArray(values.event.event.questions) &&
        values.event.event.questions.length > 0
      ) {
        values.event.event.questions.forEach((question, index) => {
          if (
            question.isRequired &&
            (!values[`question-${index}`] ||
              !values[`question-${index}`].trim())
          ) {
            allQuestionsAnswered = false;
          }
        });
      }
      if (!allQuestionsAnswered) {
        enqueueSnackbar("Please enter a value for all required questions", {
          variant: "error",
        });
        actions.setSubmitting(false);
        return;
      }

      const endDateTime = moment(values.timeSlot.value)
        .add(values.event.event.duration, "minutes")
        .utc()
        .format();
      let slotId = generateUuid();
      if (!scheduleId) {
        enqueueSnackbar("Unable to schedule with clinician", {
          variant: "error",
        });
        actions.setSubmitting(false);
        return;
      }
      let slot = createSlotResource(
        slotId,
        values.timeSlot.value,
        endDateTime,
        "busy",
        scheduleId,
        values.event.event.name
      );
      let appointment = createAppointmentResource(
        values.timeSlot.value,
        endDateTime,
        "booked",
        values.event.event.name,
        slotId,
        practitionerUser.practitionerId,
        props.selectedPatient,
        null
      );
      if (props.newPatient) {
        const patientResponse = await createNewPatientAndTask(
          props.selectedPatient,
          props.organization
        );
        if (patientResponse.code >= 400) {
          enqueueSnackbar(patientResponse.error, {
            variant: "error",
          });
          actions.setSubmitting(false);
          return;
        }
      }
      let response = await createSlotAndAppointment(slot, appointment);
      if (response.code < 400) {
        let apptMeta = await createApptMetaResource(
          appointment,
          slotId,
          values.event.event,
          values,
          practitionerUser,
          props.organization
        );
        const apptMetaResponse = await createApptMeta(apptMeta);
        if (apptMetaResponse && apptMetaResponse.code < 400) {
          apptMeta = apptMetaResponse.apptMeta;
        }
        actions.resetForm({
          event: null,
          date: null,
          timeSlot: null,
        });
        let message = "Successfully booked appointment";
        enqueueSnackbar(message, {
          variant: "success",
        });
        window.analytics.track("Appointment Self Scheduled");
        props.setAppointmentSuccess(
          values.event.event.name,
          values.event.event.description,
          appointment.start,
          appointment.end,
          apptMeta && apptMeta.videoVisit && apptMeta.videoUrl
            ? apptMeta.videoUrl
            : `${props.organization.addressLine1}${
                props.organization.addressLine2
                  ? `, ${props.organization.addressLine2}`
                  : ""
              }, ${props.organization.city}, ${props.organization.state}, ${
                props.organization.zipcode
              }`
        );
        if (values.event.event.sendPatientEmail) {
          sendEmailAppointment(
            props.newPatient,
            appointment,
            apptMeta,
            props.organization,
            props.selectedPatient,
            values.event.event,
            availability,
            true
          );
        }
        let practitionerResponse = await getPractitioner(
          practitionerUser.practitionerId
        );
        if (practitionerResponse.code < 400) {
          sendEmailNotification(
            values.event.event.name,
            appointment.start,
            props.organization,
            practitionerResponse.practitioner,
            props.selectedPatient,
            props.newPatient,
            apptMeta,
            availability
          );
        }
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
        });
        window.analytics.track("Error Submitting Appointment");
      }
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      enqueueSnackbar("Error booking appointment", {
        variant: "error",
      });
    }
  };

  let initialValues = {
    event:
      props.presetEventId && eventOptions.length > 0
        ? {
            event: eventOptions[0],
            label: eventOptions[0].name,
            value: eventOptions[0]._id,
          }
        : null,
    date: null,
    timeSlot: null,
  };

  console.debug("zzz eventOptions", eventOptions.length, initialValues);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        className={classes.form}
        validationSchema={AppointmentOptionsSchema}
        onSubmit={handleSubmit}
        id="appointmentOptionsForm"
        enableReinitialize={true}
        render={formProps => (
          <Form className="min-h-full h-auto mx-auto w-full max-w-5xl bg-white shadow-md rounded px-8 py-8 pt-8">
            <div className="mx-auto w-full max-w-xl">
              <h2 className="text-lg font-bold py-2">Book Appointment</h2>
              {(fetching && !formProps.values.event) ||
                (fetching && !availability && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                ))}
              {props.settingsFetched && error === null && (
                <Grid container className={classes.container} spacing={8}>
                  {eventOptions.length > 0 ? (
                    <Grid item xs={12}>
                      <EventSelectForm
                        events={eventOptions}
                        selectedEvent={formProps.values.event}
                        label="Select Appointment Type"
                        name="event"
                        onChange={event => {
                          formProps.setValues({
                            event: event,
                            date: null,
                            timeSlot: null,
                          });
                          availability = null;
                          handleEventChange(event, formProps);
                        }}
                        practitionerUser={practitionerUser}
                      />
                      <ErrorMessage
                        name="event"
                        component="span"
                        className={errorClass}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <h4>{noEventsMessage}</h4>
                    </Grid>
                  )}
                  {formProps.values.event &&
                    formProps.values.event.event &&
                    Array.isArray(practitioners) &&
                    practitioners.length > 1 && (
                      <Grid item xs={12}>
                        <Field
                          label="Select Practitioner"
                          classes={classes.select}
                          fullWidth
                          name="practitioner"
                          component={dropdown}
                          options={practitioners.map(practitioner => {
                            return {
                              value: practitioner.practitionerId,
                              label: parseUserName(practitioner),
                            };
                          })}
                          placeholder="Select..."
                          variant="outlined"
                          // defaultValue={formProps.values.timeSlot}
                          value={formProps.values.practitioner}
                          onChange={practitioner => {
                            formProps.setFieldValue(
                              "practitioner",
                              practitioner,
                              true
                            );
                            handlePractitionerChange(practitioner, formProps);
                          }}
                        />
                        <ErrorMessage
                          name="practitioner"
                          component="span"
                          className={errorClass}
                        />
                      </Grid>
                    )}
                  {formProps.values.event &&
                    availability &&
                    (availability.sunday ||
                      availability.monday ||
                      availability.tuesday ||
                      availability.wednesday ||
                      availability.thursday ||
                      availability.friday ||
                      availability.saturday) && (
                      <React.Fragment>
                        <Grid item xs={12}>
                          <Field
                            component={renderCalendarPicker}
                            name="calendarDate"
                            autoOk
                            orientation="landscape"
                            // openTo="date"
                            shouldDisableDate={date => {
                              switch (moment(date).day()) {
                                case 0: {
                                  return !availability.sunday;
                                }
                                case 1: {
                                  return !availability.monday;
                                }
                                case 2: {
                                  return !availability.tuesday;
                                }
                                case 3: {
                                  return !availability.wednesday;
                                }
                                case 4: {
                                  return !availability.thursday;
                                }
                                case 5: {
                                  return !availability.friday;
                                }
                                case 6: {
                                  return !availability.saturday;
                                }
                                default:
                                  return false;
                              }
                            }}
                            maxDate={moment().add(
                              formProps.values.event.event.dateRange,
                              "days"
                            )}
                            date={calendarDate}
                            showTodayButton
                            onChange={value => {
                              handlePickDate(
                                value,
                                formProps,
                                formProps.values.event,
                                practitionerUser
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="date"
                            shouldDisableDate={date => {
                              switch (moment(date).day()) {
                                case 0: {
                                  return !availability.sunday;
                                }
                                case 1: {
                                  return !availability.monday;
                                }
                                case 2: {
                                  return !availability.tuesday;
                                }
                                case 3: {
                                  return !availability.wednesday;
                                }
                                case 4: {
                                  return !availability.thursday;
                                }
                                case 5: {
                                  return !availability.friday;
                                }
                                case 6: {
                                  return !availability.saturday;
                                }
                                default:
                                  return false;
                              }
                            }}
                            component={date}
                            variant="outlined"
                            label="Select Date"
                            onChange={date => {
                              handlePickDate(date, formProps);
                            }}
                            maxDate={moment().add(
                              formProps.values.event.event.dateRange,
                              "days"
                            )}
                            enablePast={false}
                          />
                        </Grid>
                        <Hidden smDown>
                          <Grid item xs={false} />
                        </Hidden>
                        {fetching && (
                          <Grid item xs={3}>
                            <br />
                            <br />
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    )}
                  {formProps.values.event &&
                    availability &&
                    !availability.sunday &&
                    !availability.monday &&
                    !availability.tuesday &&
                    !availability.wednesday &&
                    !availability.thursday &&
                    !availability.friday &&
                    !availability.saturday && (
                      <Grid item xs={12}>
                        <b>
                          No availability for the requested provider. Please
                          reach out to the clinic directly at{" "}
                          {props.organization.phoneNumber} to inquire about an
                          appointment.
                        </b>
                      </Grid>
                    )}
                  {formProps.values.date &&
                    formProps.values.event &&
                    scheduleFetched && (
                      <Grid item xs={12}>
                        <br />
                        <Field
                          classes={classes.select}
                          fullWidth
                          name="timeSlot"
                          component={dropdown}
                          label={"Timeslot - " + timeZone}
                          options={timeSlotOptions}
                          placeholder={
                            timeSlotOptions.length
                              ? "Select..."
                              : "No available slots for this day"
                          }
                          variant="outlined"
                          defaultValue={formProps.values.timeSlot}
                          value={formProps.values.timeSlot}
                          onChange={value => {
                            formProps.setFieldValue("timeSlot", value, true);
                            formProps.setFieldValue(
                              "reminderTime",
                              moment(value.value)
                                .subtract(24, "hours")
                                .utc()
                                .format(),
                              true
                            );
                          }}
                        />
                        <ErrorMessage
                          name="timeSlot"
                          component="span"
                          className={errorClass}
                        />
                      </Grid>
                    )}
                  {formProps.values.event &&
                    formProps.values.event.event &&
                    Array.isArray(formProps.values.event.event.questions) &&
                    formProps.values.event.event.questions.length > 0 &&
                    formProps.values.event.event.questions.map(
                      (question, index) => (
                        <Grid item xs={12} key={index}>
                          <Field
                            classes={classes.paper}
                            fullWidth
                            name={`question-${index}`}
                            label={question.text}
                            component={text}
                            variant="outlined"
                            required={question.isRequired}
                          />
                          <ErrorMessage
                            name={`question-${index}`}
                            component="span"
                            className={classes.error}
                          />
                        </Grid>
                      )
                    )}
                </Grid>
              )}
              {!error && fetched && eventOptions.length > 0 && (
                <React.Fragment>
                  <br />
                  <Field
                    name="submit"
                    component={renderSubmitButton}
                    disabled={
                      availability &&
                      !availability.sunday &&
                      !availability.monday &&
                      !availability.tuesday &&
                      !availability.wednesday &&
                      !availability.thursday &&
                      !availability.friday &&
                      !availability.saturday
                    }
                  >
                    Submit
                  </Field>
                </React.Fragment>
              )}
            </div>
          </Form>
        )}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    events: state.settings.events,
    fetching: state.settings.fetching || state.schedule.fetching,
    fetched: state.settings.fetched && state.schedule.fetched,
    scheduleFetched: state.schedule.fetched,
    settingsFetched: state.settings.fetched,
    error: state.schedule.error || state.settings.error,
    selectedPatient: state.patients.selectedPatient,
    newPatient: state.patients.newPatient,
    organization: state.settings.organization,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAvailableSlots: () => dispatch({ type: REQUEST_AVAILABLE_SLOTS }),
    fetchAvailableSlotsSuccess: () =>
      dispatch({ type: FETCH_AVAILABLE_SLOTS_SUCCESS }),
    setSettingsFetching: () => dispatch({ type: SET_SCHEDULE_FETCHING }),
    fetchScheduleFailed: () => dispatch({ type: FETCH_SCHEDULE_FAILED }),
    fetchScheduleSuccess: () => dispatch({ type: FETCH_SCHEDULE_SUCCEEDED }),
    // clearStore: () => dispatch({ type: RESET_STORE }),
    setAppointmentSuccess: (title, description, startTime, endTime, location) =>
      dispatch({
        type: SET_APPOINTMENT_SUCCESS,
        title: title,
        description: description,
        startTime: startTime,
        endTime: endTime,
        location: location,
      }),
    fetchAvailableSlotsFailed: () =>
      dispatch({ type: FETCH_AVAILABLE_SLOTS_FAILED }),
    fetchPractitionersSuccess: () =>
      dispatch({ type: FETCH_PRACTITIONERS_SUCCEEDED }),
    fetchPractitionersFailed: () =>
      dispatch({ type: FETCH_PRACTITIONERS_FAILED }),
  };
};

// AppointmentOptionsForm.propTypes = {
//   practitionerId: PropTypes.string.isRequired,
// };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar(AppointmentOptionsForm)));

import React from "react";
import { Field } from "formik";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { dropdown } from "../renderers/renderers";
import { parseUserName } from "../../utils/resources";

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    minHeight: "50vh",
    maxHeight: "fit-content",
    minWidth: "fit-content",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  error: {
    color: "red",
  },
});

// const formatOptionLabel = ({ label, description }) => (
//   <div>
//     <div>{label}</div>
//     <p style={{ color: "grey" }}>{description}</p>
//   </div>
// );

let EventSelectForm = props => {
  const {
    events,
    classes,
    selectedEvent,
    name,
    label,
    practitionerUser,
    ...custom
  } = props;
  let eventOptions = [];
  for (let i = 0; i < events.length; i++) {
    eventOptions.push({
      value: events[i]._id,
      label: events[i].name,
      event: events[i],
    });
  }

  return (
    <React.Fragment>
      {!(eventOptions.length === 1 && selectedEvent) && (
        <Field
          classes={classes}
          name={name}
          component={dropdown}
          label={label}
          options={eventOptions}
          defaultValue={selectedEvent}
          {...custom}
        />
      )}
      {eventOptions.length === 1 && selectedEvent && (
        <h4 className="text-blue-700 font-bold">{selectedEvent.event.name}</h4>
      )}
      {selectedEvent && (
        <p className="pt-3 pb-1">{selectedEvent.event.description}</p>
      )}
      {selectedEvent && (
        <p className="py-1">Duration: {selectedEvent.event.duration} minutes</p>
      )}
      {selectedEvent && practitionerUser && (
        <p className="py-1">
          Provider:{" "}
          {practitionerUser && practitionerUser.firstName
            ? parseUserName(practitionerUser)
            : selectedEvent.event.practitionerName}
        </p>
      )}
      {selectedEvent &&
        selectedEvent.event &&
        selectedEvent.event.videoVisit && <p className="py-1">Video visit</p>}
    </React.Fragment>
  );
};

// const mapStateToProps = state => {
//   return {
//     events: state.settings.events,
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     getEvents: organization =>
//       dispatch({ type: REQUEST_EVENTS, payload: organization }),
//   };
// };

export default connect(
  null,
  null
)(withStyles(styles)(EventSelectForm));
